import { faCheck, faGlobe, faHand, faScrewdriverWrench, faShield, faToolbox } from '@fortawesome/free-solid-svg-icons';

export const OVERVIEW = 'overview';
export const REQUEST = 'request';
export const EVIDENCE_REVIEW = 'evidence-review';
export const CREATE_RESULT = 'create-result';
export const RESULTS_PREVIEW = 'results-preview';
export const REMEDIATION = 'remediation';
export const EDIT = 'Edit';

export const ASSESSMENT_DETAILS_SIDEBAR_MENUS = [
  { label: 'OVERVIEW', value: OVERVIEW, faIcon: faGlobe },
  { label: 'REQUEST', value: REQUEST, faIcon: faHand },
  { label: 'EVIDENCE REVIEW', value: EVIDENCE_REVIEW, faIcon: faShield },
  { label: 'CREATE RESULT', value: CREATE_RESULT, faIcon: faScrewdriverWrench },
  { label: 'RESULTS PREVIEW', value: RESULTS_PREVIEW, faIcon: faCheck },
  { label: 'REMEDIATION', value: REMEDIATION, faIcon: faToolbox },
];

export const COMPLETED_STAGE = 'completed';
export const ACTIVE_STAGE = 'active';
export const NEW_STAGE = 'new';

export const REQUEST_INDEX = 3;
export const INITIAL_ASSESSMENT_INDEX = 2;
export const REMEDIATION_INDEX = 1;
export const COMPLETED_INDEX = 0;
export const COMPLETED_STATUS = 5;

export const AWAITING_VENDOR_RESPONSE = 21;
export const QUEUED_FOR_AUDIT = 22;
export const CORL_AUDITOR_VALIDATION = 23;
export const CORL_AUDITOR_GRADING = 24;
export const ASSESSMENT_RESULT_READY = 25;
export const REMEDIATION_PLAN_REVIEW = 26;
export const PENDING_REMEDIATION_UPDATES = 27;
export const CORL_CLEARED_MAINTENANCE = 28;
export const VENDOR_REM_PLAN_REVIEW = 29;
export const TERMINATED = 30;
export const REMEDIATION_PROCESSING_VENDOR_RESPONSE = 31;

export const STATUTS_TRACKER = [
  { label: 'COMPLETED', status: NEW_STAGE },
  { label: 'REMEDIATION', status: NEW_STAGE },
  { label: 'INITIAL ASSESSMENT', status: ACTIVE_STAGE },
  { label: 'ASSESSMENT REQUEST', status: COMPLETED_STAGE },
];
export const SUB_STATUTS_TRACKER = [
  { label: 'REVIEW PLAN', status: VENDOR_REM_PLAN_REVIEW, stage: NEW_STAGE },
  { label: 'RESULT ACKNOWLEDGEMENT', status: PENDING_REMEDIATION_UPDATES, stage: NEW_STAGE },
  { label: 'RESULT PREVIEW', status: REMEDIATION_PLAN_REVIEW, stage: NEW_STAGE },
  { label: 'AUDITOR GRADING', status: CORL_AUDITOR_GRADING, stage: NEW_STAGE },
  { label: 'AUDITOR VALIDATION', status: CORL_AUDITOR_VALIDATION, stage: NEW_STAGE },
  { label: 'QUEUED FOR AUDIT', status: QUEUED_FOR_AUDIT, stage: NEW_STAGE },
  { label: 'AWAITING RESPONSE', status: AWAITING_VENDOR_RESPONSE, stage: NEW_STAGE },
  { label: 'CONFIRMATION', status: COMPLETED_STAGE, stage: COMPLETED_STAGE },
];

export const REQUESTED = 'requested';
export const SUBMITTED = 'submitted';
export const IN_PROGRESS = 'in_progress';
export const COMPLETED = 'complete';
export const UPDATE = 'update';

export const LEGACY_ASSESSMENTS_TOOLTIP_MESSAGE = 'Page not available for Legacy Asessments';

export const FULL_PERCENT = 100;
export const CALCULATED_PERCENT = 25;
export const CALCULATED_PENDING_PERCENT = 32;

export const CORL_CLEARED = 'CORL Cleared';

export const ASSESSMENT_QUESTIONNAIRE_ANSWERED_QUESTIONS_TYPE_ID = 3;

export const QUESTIONNAIRE_PROGRESS_AREA_CHART_COLORS = [
  "#145091",
  "#0083E9",
  "#855B94",
  "#F5B335",
  "#F78D2A",
]

export const CORL_CLEARED_QUESTIONNAIRE_ID = 1;
export const CONTROL_BASED_QUESTIONNAIRE_ID = 2;
export const SCOPING_BASED_QUESTIONNAIRE_ID = 8;
export const SUPPLEMENTAL_QUESTIONNAIRE_ID = 7;

export const PUSH_TO_AUDIT_REVIEW_ACTION = 7;
