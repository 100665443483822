import { NO_VALUE, YES_VALUE } from 'constants/constants';
import { isArray, isEmpty, isNil, isString, omit } from 'lodash';
import { ASSESSMENT_QUESTIONNAIRE_ANSWERED_QUESTIONS_TYPE_ID } from 'pages/Assessment/AssessmentDetails/constants';
import { QA_APPROVED_QUESTIONNAIRE_TEMPLATE, QUESTIONNAIRE_TEMPLATE_TYPE_ASSESSMENT, SCOPING_QUESTIONNAIRE_TYPE, SUPPLEMENTAL_QUESTIONNAIRE_TYPE } from 'pages/QuestionnaireBuilder/constants';

const getMappedSearchCriterion = (searchData) => {
  const criterion = [];
  Object.keys(searchData)?.forEach((key) => {
    if (isString(searchData[key]) && !isEmpty(searchData[key]) && key === 'ClientOrProductOrVendor') {
      criterion.push({
        Field: key,
        Operator: 'containsorcontains',
        Value: searchData[key],
      });
    }
    if (isString(searchData[key]) && !isEmpty(searchData[key]) && key !== 'ClientOrProductOrVendor') {
      criterion.push({
        Field: key,
        Operator: 'startswith',
        Value: searchData[key],
      });
    }
    if (isArray(searchData[key]) && searchData[key]?.length > 0) {
      criterion.push({
        Field: key,
        Operator: 'in',
        Value: searchData[key].join(','),
      });
    }
  });
  return criterion;
};

const getAssessmentSortBody = (sortModel) => {
  let sort = [];
  sortModel?.map((item) => {
    let obj = {};
    if (item?.field === 'LastStatusActivity') {
      obj = {
        field: 'LastUpdated',
        order: item?.sort?.toUpperCase(),
      };
    } else {
      obj = {
        ...item,
        order: item?.sort?.toUpperCase(),
      };
    }
    const omittedData = omit(obj, 'sort');
    sort.push(omittedData);
  });

  return sort;
};

export const getAssessmentListBody = (searchData = {}, page, pageSize, sortModel = []) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }

  let sort = getAssessmentSortBody(sortModel);

  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ClientId',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'MasterStepName',
      'AssessmentStatus',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'DynVendorAccountId',
      'AssessmentDisplayStatus',
      'Owner',
      'Domain',
      'CapabilityGroup',
      'LastUpdated',
      'ClientName',
      'AssessmentTypeId',
      'ReportInExecSummary',
      'AssessmentApplicableForES',
      'DisplayResultAndEvidence',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'ProductVendorId',
      'Source',
      'AssessmentRequestId',
      'AllAssessment',
      'AssessmentLabel',
      'IsEscalated',
    ],
    criterion: criterion,
    sort:
      sort.length > 0
        ? sort
        : [
          {
            field: 'LastUpdated',
            order: 'DESC',
          },
        ],
    page: page,
    pageSize: pageSize,
  };
};

export const getCompanionAssessmentListBody = (searchData = {}, page, pageSize, sortModel = []) => {
  let criterion = [
    {
      Field: 'AssessmentTypeId',
      Operator: 'IN',
      Value: '7',
    },
  ];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }

  let sort = getAssessmentSortBody(sortModel);

  return {
    intent: '',
    fields: [
      'AssessmentId',
      'AssessmentRequestId',
      'AssessmentName',
      'AssessmentStage',
      'ProductName',
      'LastUpdated',
      'AssessmentStatus',
      'InsertUserName',
      'CompletedDate',
      'InsertUserName',
      'UserEmail',
      'QuestionnaireTemplateName',
      'QuestionnaireTemplateTypeId',
      'VendorId',
      'VendorName',
      'ClientOrgId',
      'ClientOrgName',
      'RecipientOrgId',
      'RecipientOrgName',
      'Owner',
      'AssessmentQuestionnaireTemplateList',
    ],
    criterion: criterion,
    sort:
      sort.length > 0
        ? sort
        : [
            {
              field: 'LastUpdated',
              order: 'DESC',
            },
          ],
    page: page,
    pageSize: pageSize,
  };
};

export const getVendorListDataSearchBody = (page, searchValue) => {
  const criterion = [];
  if (!isEmpty(searchValue)) {
    criterion.push({
      field: 'VendorOrgName',
      operator: 'startswith',
      value: searchValue,
    });
  }
  return {
    intent: '',
    fields: ['VendorOrgId', 'VendorOrgName'],
    criterion: criterion,
    sort: [
      {
        field: 'VendorOrgName',
        order: 'ASC',
      },
    ],
    page: page,
    pageSize: 20,
  };
};

export const getClientListDataSearchBody = (page, searchValue) => {
  const criterion = [];
  if (!isEmpty(searchValue)) {
    criterion.push({
      field: 'ClientOrgName',
      operator: 'startswith',
      value: searchValue,
    });
  }
  return {
    intent: '',
    fields: ['ClientOgId', 'ClientOrgName'],
    criterion: criterion,
    sort: [
      {
        field: 'ClientOrgName',
        order: 'ASC',
      },
    ],
    page: page,
    pageSize: 20,
  };
};

export const getAssesmmentProductListSearchBody = (page, searchValue) => {
  const criterion = [];
  if (!isEmpty(searchValue)) {
    criterion.push({
      field: 'ProductName',
      operator: 'startswith',
      value: searchValue,
    });
  }
  return {
    intent: '',
    fields: ['ProductVendorId', 'ProductName', 'ProductNameForFilter'],
    Criterion: criterion,
    sort: [
      {
        field: 'ProductName',
        order: 'ASC',
      },
    ],
    page: page,
    pageSize: 20,
  };
};

export const getAssessmentConfigurationBody = () => {
  return {
    intent: '',
    fields: ['MasterStepName', 'AssessmentDisplayStatus', 'Status', 'AssessmentDisplayStatusDropDown'],
    criterion: [],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentListCountBody = (searchData = {}, sortModel) => {
  let criterion = [];
  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }

  return {
    intent: '',
    fields: [
      'ClientOrgId',
      'AssessmentName',
      'ClientId',
      'ClientName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'MasterStepName',
      'AssessmentTypeId',
      'CountOfAssessment',
      'DeliveryStatus',
      'DeliveryStatusName',
      'ParentAssessmentId',
      'AllAssessment',
      'AssessmentDisplayStatus',
      'IsEscalated',
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getCompanionAssessmentListCountBody = (searchData = {}, sortModel) => {
  let criterion = [
    {
      Field: 'AssessmentTypeId',
      Operator: 'IN',
      Value: '7',
    },
  ];
  const fields = ['CountOfAssessment', 'AssessmentRequestId', 'ClientOrgId', 'VendorId'];

  if (!isEmpty(searchData)) {
    criterion = [...criterion, ...getMappedSearchCriterion(searchData)];
  }

  return {
    intent: '',
    fields: fields,
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getAssessmentNotesBody = (searchData) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: searchData,
    },
  ];

  return {
    intent: '',
    fields: ['StartDate', 'NewActionDetailsComments', 'NoteComments', 'Name', 'CreatedOn'],
    Criterion: criterion,
    sort: [
      {
        field: 'CreatedOn',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};
export const getQuestionnaireInfoBody = (assessmentId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }

  return {
    Intent: '',
    Fields: [
      "ClientOrgId",
      "AssessmentName",
      "ProductId",
      "ProductName",
      "VendorId",
      "VendorName",
      "AssessmentStatus",
      "MasterStepName",
      "PortalId",
      "AssessmentStage",
      "AssessmentStartDate",
      "AssessmentCompletedDate",
      "AssessmentDisplayStatus",
      "DisplayResultAndEvidence",
      "DisplayVendorPercentage",
      "QuestionnaireSummary",
      "DeliveryStatus",
      "DeliveryStatusName",
      "ParentAssessmentId",
      "LastUpdated",
      "Source",
      "AllAssessment",
      "LatestAssessmentActionLog"
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getAssessmentOverviewBodyForPortal = (id, clientOrgId, vendorId) => {
  return {
    intent: '',
    fields: [
      'VendorId',
      'VendorName',
      'VendorDescription',
      'ProductName',
      'ProductDescription',
      'ProductUrl',
      'Environment',
      'SummaryDate',
      'ValidationTypeId',
      'ValidationTypeName',
      'LastUpdated',
      'Source',
      'DisplayExecutiveSummaryLink',
      'AssessmentType',
      'AssessmentDueDate',
      'ClientOrgId',
      'AssessmentRequestId',
      'InsertTimestamp'
    ],
    Criterion: [
      {
        Field: 'AssessmentId',
        Operator: '=',
        Value: id,
      },
      {
        Field: 'ClientOrgId',
        Operator: '=',
        Value: clientOrgId
      },
      {
        Field: 'VendorOrgId',
        Operator: '=',
        Value: vendorId
      }
    ],
    sort: [],
    page: 0,
    pageSize: 0,
  };
};
export const getQuestionnaireInfoBodyForAssessment = (searchData) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: searchData,
    },
  ];

  return {
    Intent: '',
    Fields: [
      'AssessmentName',
      'ProductId',
      'ProductName',
      'VendorId',
      'VendorName',
      'AssessmentStatus',
      'MasterStepName',
      'PortalId',
      'AssessmentStage',
      'AssessmentStartDate',
      'AssessmentCompletedDate',
      'AssessmentDisplayStatus',
      'DisplayResultAndEvidence',
      'DisplayVendorPercentage',
      'QuestionnaireSummary',
      'DeliveryStatus',
      'DeliveryStatusName',
      'LastUpdated',
      "CountOfMandatoryQuestions",
      "CountOfMandatoryAnswers",
      "QuestionnaireTemplateId",
      "QuestionnaireTemplateType",
      "AssessmentStatus",
      "QuestionnaireTemplateTypeId",
      "QuestionnaireTemplateName",
      "QuestionnaireTemplateStatus",
      "CountOfRequirement",
      "CountOfSubmittedRequirement",
      "AssessmentQuestionnaireId",
      "Source"
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getCompanionQuestionnaireInfoBodyForAssessment = (searchData) => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: searchData,
    },
  ];

  return {
    Intent: '',
    Fields: ['AssessmentName', 'AssessmentStatus', 'VendorId', 'VendorName', 'QuestionnaireSummary', 'LastUpdated'],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getSelectedAssessmentDetailsBody = (assessmentId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }

  return {
    intent: '',
    fields: [
      "ClientOrgId",
      "ClientName",
      "AssessmentName",
      "ProductId",
      "ProductName",
      "VendorId",
      "VendorName",
      "AssessmentStatus",
      "MasterStepName",
      "PortalId",
      "AssessmentStage",
      "AssessmentStartDate",
      "AssessmentCompletedDate",
      "AssessmentDisplayStatus",
      "DisplayResultAndEvidence",
      "DisplayVendorPercentage",
      "QuestionnaireSummary",
      "DeliveryStatus",
      "DeliveryStatusName",
      "ParentAssessmentId",
      "LastUpdated",
      "Source",
      "ProductVendorId",
      "AssessmentRequestId",
      "AllAssessment",
      "EditAssessmentOptionList",
      "IsEscalated",
      "IsExpedite",
      "IsPaused",
      "EditAssessmentOptionId",
      "LatestAssessmentActionLog"
    ],
    criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

/* Assessment Details  Evidence Review - Utils*/
export const getVendorScopeInfoSearchBody = (productVendorId) => {
  let criterion = [];

  if (!isNil(productVendorId)) {
    criterion.push({
      field: 'ProductVendorId',
      operator: '=',
      value: productVendorId,
    });
  }
  return {
    "Intent": "",
    "Fields": [
      "ProductVendorId",
      "ProductComponentName",
      "ProductComponentType",
      "ProductComponentDataLocation",
      "ProductMedicalDevice",
      "DataLeavesClientSystem",
      "PIIDataAccess",
      "PHIDataAccess",
      "PCIDataAccess",
      "EmployeeProprietaryDataAccess",
      "VendorOrgId",
      "VendorOrgName",
      "DomainName",
      "ProductName",
      "ProductDescription",
      "ProductComponentOverview",
      "CCDynVRASId"
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0
  }
}

export const getAssessmentQuestionnaireRequirementListSearchBody = (assessmentId, assessmentQuestionnaireRequirementId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      field: 'AssessmentId',
      operator: '=',
      value: assessmentId,
    });
  }

  if (!isNil(assessmentQuestionnaireRequirementId)) {
    let parsedId = parseInt(assessmentQuestionnaireRequirementId);
    if (parsedId) {
      criterion.push({
        field: 'AssessmentQuestionnaireRequirementId',
        operator: '=',
        value: parsedId,
      });
    }
    else {
      criterion.push({
        "field": "IsAuditorReviewRequired",
        "operator": "=",
        "value": YES_VALUE
      });
    }

  }

  return {
    "Intent": "",
    "Fields": [
      "AssessmentQuestionnaireId",
      "InadequacyReasonList",
      "RequirementId",
      "Adequacy",
      "RequirementNumber",
      "RequirementName",
      "RequirementType",
      "ProductVendorId",
      "SystemAdequacy",
      "AuditorAdequacy",
      "QuestionnaireTemplateRequirementId",
      "IsAuditorReviewRequired",
      "IsAuditorConfirmed",
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0
  }
}

export const getVendorProductRequirementDetailsSearchBody = (assessmentQuestionnaireRequirementId) => {
  let criterion = [];

  if (!isNil(assessmentQuestionnaireRequirementId)) {
    criterion.push({
      field: 'AssessmentQuestionnaireRequirementId',
      operator: '=',
      value: assessmentQuestionnaireRequirementId,
    });
  }

  return {
    "Intent": "",
    "Fields": [
      "AssessmentQuestionnaireId",
      "RequirementId",
      "Adequacy",
      "RequirementNumber",
      "RequirementName",
      "ProductVendorId",
      "AssessmentQuestionnaireQuestionResponseList",
      "ResponseAttachmentList",
      "InadequacyReasonList",
      "AssessmentQuestionnaireRequirementId",
      "QuestionnaireTemplateRequirementId",
      "RecommendationStatus",
      "AssessmentInsertTimestamp",
      "InadequecyReasonIPIList",
      "InadequecyReasonIPIAuditReviewRequiredList",
      "NotesToVendor",
      "ResultsOfTesting",
    ],
    "Criterion": criterion,
    "Sort": [],
    "Page": 0,
    "PageSize": 0
  }
}


export const getStatusHistory = id => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: id,
    },
  ];
  return {
    intent: '',
    fields: [
      'ResponseId',
      'CreatedDate',
      'LastUpdated',
      'StatusName',
      'MasterStep',
      'AssessmentDisplayStatus',
      'AssessmentStatusName',
      'EnvironmentId',
      'MaxLastUpdated'
    ],
    criterion: criterion,
    sort: [
      {
        'Field': 'CreatedDate',
        'order': 'DESC'
      }
    ],
    page: 0,
    pageSize: 0,
  };
}

export const getStatusHistoryForPortal = id => {
  let criterion = [
    {
      Field: 'AssessmentId',
      Operator: '=',
      Value: id,
    },
  ];
  return {
    intent: '',
    fields: [
      'ResponseId',
      'CreatedDate',
      'LastUpdated',
      'EnvironmentId',
      'MaxLastUpdated',
      'ReviewComment',
      'AssessmentStatus',
      'AssessmentDisplayStatus'
    ],
    criterion: criterion,
    sort: [
      {
        'Field': 'CreatedDate',
        'order': 'DESC'
      }
    ],
    page: 0,
    pageSize: 0,
  };
}

export const getRemediationGuidanceSearchBodyForPortal = (assessmentId, assessmentQuestionnaireRequirementId, clientOrgId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion = [
      {
        field: 'AssessmentId',
        operator: '=',
        value: assessmentId,
      },
    ];
  }
  if (!isNil(clientOrgId)) {
    criterion.push({
      field: 'ClientOrgId',
      operator: '=',
      value: clientOrgId,
    });
  }
  if (!isNil(assessmentQuestionnaireRequirementId)) {
    criterion.push({
      field: 'AssessmentQuestionnaireRequirementId',
      operator: '=',
      value: assessmentQuestionnaireRequirementId,
    });
  }
  return {
    intent: '',
    fields: [
      "RecommendationStatus",
      "NotesToVendor",
      "QuestionnaireTemplateRequirementId",
      "AssessmentQuestionnaireId",
      "RequirementId",
      "RequirementStatus",
      "RequirementNumber",
      "RequirementName",
      "RequirementType",
      "RequirementGroup",
      "ControlNumber",
      "DefaultTier",
      "AssessmentId",
      "QuestionnaireTemplateId",
      "AssessmentQuestionnaireName",
      "AssessmentName",
      "ProductVendorId",
      "RecommendationStatus",
      "Severity",
      "InadequecyReasonIPIList",
      "QuestionnaireTemplateRequirementId",
      "AcceptedByVendor",
      "RemediationGuidance",
      "CountOfHighRiskFindings",
      "TotalCountOfRecommendation",
      "CumulativeDueDays",
      "IsTicketClosed",
      "CountOfClientAcceptedRemediation",
      "CountOfPendingVendorRiskFindings",
      "CountOfResolvedRiskFindings",
      "CountOfPendingClientRiskFindings",
      "RemediationDueDate",
      "AssessmentType",
      "Adequacy"
    ],
    Criterion: criterion,
    sort: [],
    page: 0,
    pageSize: 0,
  };
};

export const getRemediationGuidanceSearchBody = (assessmentId = '', riskSummaryId = '') => {
  let criterion = [];
  if (!isEmpty(assessmentId)) {
    criterion = [
      {
        field: 'AssessmentId',
        operator: '=',
        value: assessmentId,
      },
    ];
  }
  if (!isEmpty(riskSummaryId)) {
    criterion.push({
      field: 'RiskSummaryId',
      operator: '=',
      value: riskSummaryId,
    });
  }
  return {
    intent: '',
    fields: [
      'EnvironmentId',
      'EnvironmentName',
      'RiskSummaryId',
      'RiskNumber',
      'Priority',
      'PriorityName',
      'RiskDescription',
      'ControlNumber',
      'ControlName',
      'StatusId',
      'Status',
      'CompletionDate',
      'RemediationStrategyAcceptByVendor',
      'AcceptedByVendor',
      'ContractuallyAgreedUponDate',
      'RecommendedDueDate',
      'ProposedRecommendation',
      'AssessmentResults',
      'ReviewComments',
      'ResultsOfTesting',
      'RiskNumber',
      'RemediationGuidance',
      'LastUpdated',
      'RiskStrategyRecommendationId',
      'RequiresRemediation',
      'RequiresRemediation_Value',
      'Source'
    ],
    Criterion: criterion,
    sort: [
      {
        Field: 'RiskNumber',
        Order: 'ASC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const searchAdditionalNotesBody = (assessmentId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    });
  }
  return {
    intent: '',
    fields: [
      "AssessmentAdditionalNoteId",
      "AssessmentId",
      "Comment",
      "InsertUserId",
      "InsertTimestamp",
      "FirstName",
      "LastName"
    ],
    Criterion: criterion,
    sort: [{
      Field: 'InsertTimestamp',
      Order: 'DESC'
    }],
    page: 0,
    pageSize: 0,
  };
}

export const getQuestionnaireListSearchBody = () => {

  return {
    intent: '',
    fields: [
      'Status',
      'QuestionnaireTemplateName',
      'QuestionnaireTemplateType',
      'VersionNumber',
      'ReferenceId',
      'ReferenceName',
      'Status',
      'LastUpdated',
    ],
    criterion: [
      {
        field: 'QuestionnaireTemplateTypeId',
        operator: 'in',
        value: `${QUESTIONNAIRE_TEMPLATE_TYPE_ASSESSMENT}, ${SUPPLEMENTAL_QUESTIONNAIRE_TYPE}`,
      },
      {
        field: 'Status',
        operator: '=',
        value: `${QA_APPROVED_QUESTIONNAIRE_TEMPLATE}`,
      }
    ],
    sort: [
      {
        field: 'LastUpdated',
        order: 'DESC',
      },
    ],
    page: 0,
    pageSize: 0,
  };
};

export const addQuestionnaireToAssessmentBody = (assessmentId, searchData) => {

  return {
    "AssessmentId": assessmentId,
    "SecurityQuestionnaireTemplateList": searchData
  }
}

export const getAssessmentQuestionnaireProgressSearchBody = (assessmentId, assessmentQuestionnaireId) => {
  let criterion = [];
  if (!isNil(assessmentId)) {
    criterion.push({
      Field: 'AssessmentId',
      Operator: '=',
      Value: assessmentId,
    },
      {
        "field": "ParentQuestionId",
        "operator": "=",
        "value": NO_VALUE
      },
      {
        "field": "QuestionResponseCategoryId",
        "operator": "=",
        "value": ASSESSMENT_QUESTIONNAIRE_ANSWERED_QUESTIONS_TYPE_ID
      }
    );
  }
  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    });
  }
  return {
    "intent": "",
    "fields": [
      "AssessmentQuestionnaireId",
      "AssessmentQuestionnaireQuestionResponseId",
      "AssessmentQuestionnaireQuestionResponseLogId",
      "ResponseDate",
      "AssessmentQuestionnaireProgress",
      "AssessmentQuestionnaireProgressDateWiseList",
      "QuestionnaireTemplateName"
    ],
    "Criterion": criterion,
    "sort": [],
    "page": 0,
    "pageSize": 0
  }
}

export const getAssessmentQuestionnaireRequirementSearchBody = (assessmentQuestionnaireId) => {
  return {
    Intent: '',
    Fields: [
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementGroup',
      'RequirementName',
      'AssessmentId',
      'QuestionnaireTemplateId',
      'UpdateTimestamp',
      'CountOfMandatoryAnswers',
      'CountOfMandatoryQuestions',
      'CountOfAnswers',
      'CountOfQuestions',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
      'RequirementNumber',
      'AssessmentName',
      'AssessmentStatus',
      'RequirementStatus',
      'IsMet',
      'LastUpdated',
      'AssessmentQuestionnaireRequirementId',
      'DynRequirementId',
      'RequirementMetUnMetStatus',
      'ExternalTicketId',
      'Adequacy',
      'IsSystem',
      'IsValidated',
    ],
    Criterion: [
      {
        Field: 'AssessmentQuestionnaireId',
        Operator: '=',
        Value: assessmentQuestionnaireId,
      },
    ],
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};

export const getAssessmentQuestionnaireRequirementGroupSearchBody = (assessmentQuestionnaireId = null) => {

  let criterion = [];
  if (!isNil(assessmentQuestionnaireId)) {
    criterion.push({
      Field: 'AssessmentQuestionnaireId',
      Operator: '=',
      Value: assessmentQuestionnaireId,
    });
  }

  return {
    Intent: '',
    Fields: [
      'AssessmentId',
      'AssessmentQuestionnaireId',
      'RequirementId',
      'RequirementNumber',
      'RequirementName',
      'RequirementType',
      'RequirementStatus',
      'RequirementGroup',
      'ControlNumber',
      'CountOfParentQuestions',
      'CountOfParentQuestionAnswers',
    ],
    Criterion: criterion,
    Sort: [],
    Page: 0,
    PageSize: 0,
  };
};