import axios from 'axios';
import { setErrors } from './errorHandler';
import { getErrorObject } from 'utils/apiUtils';
import { NO_DATA_FOUND } from 'constants/errorMessage';
import { DEFAULT_ROWS_PER_PAGE, PORTAL_2 } from 'constants/constants';
import {
  addQuestionnaireToAssessmentBody,
  getAssesmmentProductListSearchBody,
  getAssessmentConfigurationBody,
  getAssessmentListBody,
  getAssessmentListCountBody,
  getCompanionAssessmentListBody,
  getCompanionAssessmentListCountBody,
  getAssessmentNotesBody,
  getAssessmentOverviewBodyForPortal,
  getAssessmentQuestionnaireProgressSearchBody,
  getAssessmentQuestionnaireRequirementGroupSearchBody,
  getAssessmentQuestionnaireRequirementListSearchBody,
  getAssessmentQuestionnaireRequirementSearchBody,
  getClientListDataSearchBody,
  getQuestionnaireInfoBody,
  getQuestionnaireInfoBodyForAssessment,
  getCompanionQuestionnaireInfoBodyForAssessment,
  getQuestionnaireListSearchBody,
  getRemediationGuidanceSearchBody,
  getRemediationGuidanceSearchBodyForPortal,
  getSelectedAssessmentDetailsBody,
  getStatusHistory,
  getStatusHistoryForPortal,
  getVendorListDataSearchBody,
  getVendorProductRequirementDetailsSearchBody,
  getVendorProductRequirementListSearchBody,
  getVendorScopeInfoSearchBody,
  searchAdditionalNotesBody,
} from 'utils/assessmentUtils';

export const SEARCH_ASSESSMENT_LIST_LOADING = '@@assessments/SEARCH_ASSESSMENT_LIST_LOADING';
export const SEARCH_ASSESSMENT_LIST_SUCCESS = '@@assessments/SEARCH_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_LIST_FAILURE = '@@assessments/SEARCH_ASSESSMENT_LIST_FAILURE';

export const getAssessmentListData =
  (formData = {}, page, rowPerPage, sortModel = []) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_LIST_LOADING });
    try {
      const searchData = {
        ClientProductVendor: formData?.ClientProductVendor,
        ClientOrgId: formData?.ClientOrgId,
        VendorName: formData?.VendorOrgName,
        ProductName: formData?.ProductName,
        AssessmentTypeId: formData?.AssessmentRequestTypeId,
        AssessmentDisplayStatus: formData?.AssessmentStatusId,
        AssessmentId: formData?.AssessmentId,
        AssessmentLabel: formData?.AssessmentLabel,
      };
      const body = getAssessmentListBody(searchData, page, rowPerPage, sortModel);
      const response = await axios.post(`serviceprovider/legacy/assessment/list`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_COMPANION_ASSESSMENT_LIST_LOADING = '@@assessments/SEARCH_COMPANION_ASSESSMENT_LIST_LOADING';
export const SEARCH_COMPANION_ASSESSMENT_LIST_SUCCESS = '@@assessments/SEARCH_COMPANION_ASSESSMENT_LIST_SUCCESS';
export const SEARCH_COMPANION_ASSESSMENT_LIST_FAILURE = '@@assessments/SEARCH_COMPANION_ASSESSMENT_LIST_FAILURE';

export const getCompanionAssessmentListData =
  (formData = {}, page, rowPerPage, sortModel = []) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_COMPANION_ASSESSMENT_LIST_LOADING });
    try {
      const searchData = {
        ClientOrProductOrVendor: formData?.ClientProductVendor,
        ClientOrgId: formData?.ClientOrgId,
        VendorOrgName: formData?.VendorOrgName,
        ProductName: formData?.ProductName,
        AssessmentStatus: formData?.AssessmentStatusId,
        AssessmentId: formData?.AssessmentId,
      };
      const body = getCompanionAssessmentListBody(searchData, page, rowPerPage, sortModel);
      const response = await axios.post(`/assessment/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_COMPANION_ASSESSMENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_COMPANION_ASSESSMENT_LIST_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_COMPANION_ASSESSMENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_CLIENT_LIST_LOADING = '@@assessments/SEARCH_CLIENT_LIST_LOADING';
export const SEARCH_CLIENT_LIST_SUCCESS = '@@assessments/SEARCH_CLIENT_LIST_SUCCESS';
export const SEARCH_CLIENT_LIST_FAILURE = '@@assessments/SEARCH_CLIENT_LIST_FAILURE';

export const getClientListData =
  (page = 1, searchValue = '', callback) =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_CLIENT_LIST_LOADING,
    });
    const body = getClientListDataSearchBody(page, searchValue);
    try {
      const res = await axios.post(`/serviceprovider/client/search`, body);
      if (res?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_CLIENT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_CLIENT_LIST_SUCCESS,
          payload: res?.data,
          searchClientValue: searchValue,
        });
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: SEARCH_CLIENT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_VENDOR_LIST_LOADING = '@@assessments/SEARCH_VENDOR_LIST_LOADING';
export const SEARCH_VENDOR_LIST_SUCCESS = '@@assessments/SEARCH_VENDOR_LIST_SUCCESS';
export const SEARCH_VENDOR_LIST_FAILURE = '@@assessments/SEARCH_VENDOR_LIST_FAILURE';

export const getVendorListData =
  (page = 1, searchValue = '', callback) =>
  async (dispatch) => {
    dispatch({
      type: SEARCH_VENDOR_LIST_LOADING,
    });
    const body = getVendorListDataSearchBody(page, searchValue);
    try {
      const res = await axios.post(`serviceprovider/vendor/search`, body);
      if (res?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_VENDOR_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_VENDOR_LIST_SUCCESS,
          payload: res?.data,
          searchVendorValue: searchValue,
        });
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: SEARCH_VENDOR_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_ASSESSMENT_PRODUCT_LIST_LOADING = '@@assessments/SEARCH_ASSESSMENT_PRODUCT_LIST_LOADING';
export const SEARCH_ASSESSMENT_PRODUCT_LIST_SUCCESS = '@@assessments/SEARCH_ASSESSMENT_PRODUCT_LIST_SUCCESS';
export const SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE = '@@assessments/SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE';

export const getAssessmentProductList =
  (page = 1, searchValue = '', callback) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_PRODUCT_LIST_LOADING });
    try {
      const body = getAssesmmentProductListSearchBody(page, searchValue);
      const response = await axios.post(`/serviceprovider/assessmentrequest/product/search`, body);
      if (response?.data?.ErrorCount > 0) {
        const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_PRODUCT_LIST_SUCCESS,
          payload: response?.data || [],
          searchProductValue: searchValue,
        });
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_PRODUCT_LIST_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_ASSESSMENT_CONFIG_LOADING = '@assessments/SEARCH_ASSESSMENT_CONFIG_LOADING';
export const SEARCH_ASSESSMENT_CONFIG_SUCCESS = '@assessments/SEARCH_ASSESSMENT_CONFIG_SUCCESS';
export const SEARCH_ASSESSMENT_CONFIG_FAILURE = '@assessments/SEARCH_ASSESSMENT_CONFIG_FAILURE';

export const getAssessmentConfiguration = () => async (dispatch) => {
  dispatch({ type: SEARCH_ASSESSMENT_CONFIG_LOADING });
  try {
    const body = getAssessmentConfigurationBody();
    const response = await axios.post(`/legacy/assessment/configuration/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: SEARCH_ASSESSMENT_CONFIG_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SEARCH_ASSESSMENT_CONFIG_SUCCESS,
        payload: response?.data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SEARCH_ASSESSMENT_CONFIG_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_ASSESSMENT_LIST_COUNT_LOADING = '@@assessments/SEARCH_ASSESSMENT_LIST_COUNT_LOADING';
export const SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS = '@@assessments/SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS';
export const SEARCH_ASSESSMENT_LIST_COUNT_FAILURE = '@@assessments/SEARCH_ASSESSMENT_LIST_COUNT_FAILURE';

export const getAssessmentListCountData =
  (formData = {}, sortModel = []) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_ASSESSMENT_LIST_COUNT_LOADING });
    try {
      const searchData = {
        ClientProductVendor: formData?.ClientProductVendor,
        ClientOrgId: formData?.ClientOrgId,
        VendorName: formData?.VendorOrgName,
        ProductName: formData?.ProductName,
        AssessmentTypeId: formData?.AssessmentRequestTypeId,
        AssessmentDisplayStatus: formData?.AssessmentStatusId,
        AssessmentId: formData?.AssessmentId,
        AssessmentLabel: formData?.AssessmentLabel,
      };
      const body = getAssessmentListCountBody(searchData, sortModel);
      const response = await axios.post(`serviceprovider/legacy/assessment/list`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
          payload: 0,
        });
      } else {
        dispatch({
          type: SEARCH_ASSESSMENT_LIST_COUNT_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_ASSESSMENT_LIST_COUNT_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_LOADING = '@@assessments/SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_LOADING';
export const SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_SUCCESS = '@@assessments/SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_SUCCESS';
export const SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_FAILURE = '@@assessments/SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_FAILURE';

export const getCompanionAssessmentListCountData =
  (formData = {}, sortModel = []) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_LOADING });
    try {
      const searchData = {
        ClientOrProductOrVendor: formData?.ClientProductVendor,
        ClientOrgId: formData?.ClientOrgId,
        VendorOrgName: formData?.VendorOrgName,
        ProductName: formData?.ProductName,
        AssessmentStatus: formData?.AssessmentStatusId,
        AssessmentId: formData?.AssessmentId,
      };
      const body = getCompanionAssessmentListCountBody(searchData, sortModel);
      const response = await axios.post(`/assessment/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_FAILURE,
          payload: 0,
        });
      } else {
        dispatch({
          type: SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_COMPANION_ASSESSMENT_LIST_COUNT_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SET_VENDOR_LIST_PAGE = '@@assessments/SET_VENDOR_LIST_PAGE';

export const setVendorListPage =
  (page = 1, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_VENDOR_LIST_PAGE,
      payload: page,
    });
    if (callback) {
      callback();
    }
  };

export const UPDATE_VENDOR_LIST = '@@assessments/UPDATE_VENDOR_LIST';

export const updateVendorList =
  (list = [], callback) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_VENDOR_LIST,
      payload: list,
    });
    if (callback) {
      callback();
    }
  };

export const SET_CLIENT_LIST_PAGE = '@@assessments/SET_CLIENT_LIST_PAGE';

export const setClientListPage =
  (page = 1, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_CLIENT_LIST_PAGE,
      payload: page,
    });
    if (callback) {
      callback();
    }
  };

export const UPDATE_CLIENT_LIST = '@@assessments/UPDATE_CLIENT_LIST';

export const updateClientList =
  (list = [], callback) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_CLIENT_LIST,
      payload: list,
    });
    if (callback) {
      callback();
    }
  };

export const SET_PRODUCT_LIST_PAGE = '@@assessments/SET_PRODUCT_LIST_PAGE';

export const setProductListPage =
  (page = 1, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_PRODUCT_LIST_PAGE,
      payload: page,
    });
    if (callback) {
      callback();
    }
  };

export const UPDATE_PRODUCT_LIST = '@@assessments/UPDATE_PRODUCT_LIST';

export const updateProductList =
  (list = [], callback) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_LIST,
      payload: list,
    });
    if (callback) {
      callback();
    }
  };
export const SET_ASSESSMENT_PAGE_NO = '@@assessments/SET_ASSESSMENT_PAGE_NO';

export const setAssessmentPageNo =
  (page = 0) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_PAGE_NO,
      payload: page,
    });
  };

export const SET_ASSESSMENT_ROW_PER_PAGE = '@@assessments/SET_ASSESSMENT_ROW_PER_PAGE';

export const setAssessmentRowPerPage =
  (page = DEFAULT_ROWS_PER_PAGE, callback) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_ROW_PER_PAGE,
      payload: page,
    });
    if (callback) {
      callback(page);
    }
  };

export const SET_ASSESSMENT_FILTERS = '@@assessments/SET_ASSESSMENT_FILTERS';

export const setAssessmentFilters =
  (data = {}) =>
  async (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_FILTERS,
      payload: data,
    });
  };

export const SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET = '@assessments/SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET';

export const setOpenAssessmentDetailsSidesheet = (value) => async (dispatch) => {
  dispatch({
    type: SET_OPEN_ASSESSMENT_DETAILS_SIDESHEET,
    payload: value,
  });
};

export const SET_SELECTED_ASSESSMENT_DETAILS_CARD = '@assessments/SET_SELECTED_ASSESSMENT_DETAILS_CARD';

export const setSelectedAssessmentCardValue = (card) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_ASSESSMENT_DETAILS_CARD,
    payload: card,
  });
};

export const RESET_QUESTIONAIRE_SUMMARY = '@assessments/RESET_QUESTIONAIRE_SUMMARY';

export const resetQuestionaireSummary = () => async (dispatch) => {
  dispatch({
    type: RESET_QUESTIONAIRE_SUMMARY,
  });
};
export const GET_ASSESSMENT_NOTES_LOADING = '@assessments/GET_ASSESSMENT_NOTES_LOADING';
export const GET_ASSESSMENT_NOTES_SUCCESS = '@assessments/GET_ASSESSMENT_NOTES_SUCCESS';
export const GET_ASSESSMENT_NOTES_FAILURE = '@assessments/GET_ASSESSMENT_NOTES_FAILURE';

export const getAssessmentNotes =
  (searchData = {}) =>
  async (dispatch) => {
    dispatch({ type: GET_ASSESSMENT_NOTES_LOADING });
    try {
      const body = getAssessmentNotesBody(searchData);
      const response = await axios.post(`/legacy/assessment/notes/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: GET_ASSESSMENT_NOTES_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_ASSESSMENT_NOTES_SUCCESS,
          payload: response?.data?.Data?.Rows || [],
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ASSESSMENT_NOTES_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const GET_QUESTIONNAIRE_INFO_LOADING = '@assessments/GET_QUESTIONNAIRE_INFO_LOADING';
export const GET_QUESTIONNAIRE_INFO_SUCCESS = '@assessments/GET_QUESTIONNAIRE_INFO_SUCCESS';
export const GET_QUESTIONNAIRE_INFO_FAILURE = '@assessments/GET_QUESTIONNAIRE_INFO_FAILURE';

export const getQuestionnaireInformation =
  (assessmentId, callback, source = null) =>
  async (dispatch) => {
    dispatch({ type: GET_QUESTIONNAIRE_INFO_LOADING });
    try {
      let response;
      if (source === PORTAL_2) {
        const body = getQuestionnaireInfoBodyForAssessment(assessmentId);
        response = await axios.post(`/assessment/search`, body);
      } else {
        const body = getQuestionnaireInfoBody(assessmentId);
        response = await axios.post(`/legacy/assessment/search`, body);
      }
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: GET_QUESTIONNAIRE_INFO_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_QUESTIONNAIRE_INFO_SUCCESS,
          payload: response?.data?.Data?.Rows || [],
        });
        dispatch(setSelectedAssessmentCardValue(response?.data?.Data?.Rows[0]));
        if (callback) {
          callback();
        }
      }
    } catch (err) {
      dispatch({
        type: GET_QUESTIONNAIRE_INFO_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const GET_COMPANION_QUESTIONNAIRE_INFO_LOADING = '@assessments/GET_COMPANION_QUESTIONNAIRE_INFO_LOADING';
export const GET_COMPANION_QUESTIONNAIRE_INFO_SUCCESS = '@assessments/GET_COMPANION_QUESTIONNAIRE_INFO_SUCCESS';
export const GET_COMPANION_QUESTIONNAIRE_INFO_FAILURE = '@assessments/GET_COMPANION_QUESTIONNAIRE_INFO_FAILURE';

export const getCompanionQuestionnaireInformation = (assessmentId, callback) => async (dispatch) => {
  dispatch({ type: GET_COMPANION_QUESTIONNAIRE_INFO_LOADING });
  try {
    const body = getCompanionQuestionnaireInfoBodyForAssessment(assessmentId);
    const response = await axios.post(`/assessment/search`, body);
    if (response?.data?.ErrorCount > 0) {
      dispatch({
        type: GET_COMPANION_QUESTIONNAIRE_INFO_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_COMPANION_QUESTIONNAIRE_INFO_SUCCESS,
        payload: response?.data?.Data?.Rows || [],
      });
      dispatch(setSelectedAssessmentCardValue(response?.data?.Data?.Rows[0]));
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: GET_COMPANION_QUESTIONNAIRE_INFO_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const SEARCH_SELECTED_ASSESSMENT_LOADING = '@@assessments/SEARCH_SELECTED_ASSESSMENT_LOADING';
export const SEARCH_SELECTED_ASSESSMENT_SUCCESS = '@@assessments/SEARCH_SELECTED_ASSESSMENT_SUCCESS';
export const SEARCH_SELECTED_ASSESSMENT_FAILURE = '@@assessments/SEARCH_SELECTED_ASSESSMENT_FAILURE';

export const getSelectedAssessmentDetails =
  (assessmentId = null, callback) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_SELECTED_ASSESSMENT_LOADING });
    try {
      const body = getSelectedAssessmentDetailsBody(assessmentId);
      const response = await axios.post(`legacy/assessment/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_SELECTED_ASSESSMENT_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_SELECTED_ASSESSMENT_SUCCESS,
          payload: response?.data?.Data?.Rows || [],
        });
        if (callback) {
          callback(response?.data?.Data?.Rows);
        }
      }
    } catch (err) {
      dispatch({
        type: SEARCH_SELECTED_ASSESSMENT_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const SEARCH_SELECTED_PORTAL_ASSESSMENT_LOADING = '@@assessments/SEARCH_SELECTED_PORTAL_ASSESSMENT_LOADING';
export const SEARCH_SELECTED_PORTAL_ASSESSMENT_SUCCESS = '@@assessments/SEARCH_SELECTED_PORTAL_ASSESSMENT_SUCCESS';
export const SEARCH_SELECTED_PORTAL_ASSESSMENT_FAILURE = '@@assessments/SEARCH_SELECTED_PORTAL_ASSESSMENT_FAILURE';

export const getSelectedPortalAssessmentDetails =
  (assessmentId = null, clientId = null, vendorId = null) =>
  async (dispatch) => {
    dispatch({ type: SEARCH_SELECTED_PORTAL_ASSESSMENT_LOADING });
    try {
      const body = getAssessmentOverviewBodyForPortal(assessmentId, clientId, vendorId);
      const response = await axios.post(`assessment/search`, body);
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: SEARCH_SELECTED_PORTAL_ASSESSMENT_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: SEARCH_SELECTED_PORTAL_ASSESSMENT_SUCCESS,
          payload: response?.data?.Data?.Rows || [],
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_SELECTED_PORTAL_ASSESSMENT_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

/* Evidence Review Actions */

export const VENDOR_PROVIDED_SCOPE_INFO_LOADING = '@@assessments/VENDOR_PROVIDED_SCOPE_INFO_LOADING';
export const VENDOR_PROVIDED_SCOPE_INFO_SUCCESS = '@@assessments/VENDOR_PROVIDED_SCOPE_INFO_SUCCESS';
export const VENDOR_PROVIDED_SCOPE_INFO_FAILURE = '@@assessments/VENDOR_PROVIDED_SCOPE_INFO_FAILURE';

export const searchVendorScopeInfo =
  (productVendorId = null) =>
  async (dispatch) => {
    dispatch({ type: VENDOR_PROVIDED_SCOPE_INFO_LOADING });
    try {
      let body = getVendorScopeInfoSearchBody(productVendorId);

      const response = await axios.post('serviceprovider/vendorproduct/component/search', body);
      if (response?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: VENDOR_PROVIDED_SCOPE_INFO_FAILURE,
        });
      } else {
        dispatch({
          type: VENDOR_PROVIDED_SCOPE_INFO_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: VENDOR_PROVIDED_SCOPE_INFO_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_LOADING = '@@assessments/ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_LOADING';
export const ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_SUCCESS = '@@assessments/ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_SUCCESS';
export const ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE = '@@assessments/ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE';

export const searchAssessmentQuestionnaireRequirementList =
  (assessmentId = null, assessmentQuestionnaireRequirementId = null) =>
  async (dispatch) => {
    dispatch({ type: ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_LOADING });

    try {
      let body = getAssessmentQuestionnaireRequirementListSearchBody(assessmentId, assessmentQuestionnaireRequirementId);

      const response = await axios.post('serviceprovider/assessmentquestionnairerequirement/search', body);
      if (response?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE,
        });
      } else {
        dispatch({
          type: ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_SUCCESS,
          payload: response?.data || [],
        });
      }
    } catch (err) {
      dispatch({
        type: ASSESSMENT_QUESTIONNAIRE_REQUIREMENTS_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_LOADING = '@@assessments/ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_LOADING';
export const ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_SUCCESS = '@@assessments/ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_SUCCESS';
export const ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE = '@@assessments/ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE';

export const searchVendorProductRequirementDetails = (requirementId, assessmentQuestionnaireRequirementId) => async (dispatch) => {
  dispatch({ type: ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_LOADING });
  try {
    let body = getVendorProductRequirementDetailsSearchBody(assessmentQuestionnaireRequirementId);

    const response = await axios.post('serviceprovider/assessmentquestionnairerequirement/search', body);
    if (response?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE,
      });
    } else {
      dispatch({
        type: ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_SUCCESS,
        payload: response?.data || [],
        RequirementId: requirementId,
      });
    }
  } catch (err) {
    dispatch({
      type: ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_DETAILS_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_CONFIRM_AUDITOR_AFFORDANCE_LOADING = '@@assessments/POST_CONFIRM_AUDITOR_AFFORDANCE_LOADING';
export const POST_CONFIRM_AUDITOR_AFFORDANCE_SUCCESS = '@@assessments/POST_CONFIRM_AUDITOR_AFFORDANCE_SUCCESS';
export const POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE = '@@assessments/POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE';

export const confirmAuditorAffordance = (formData) => async (dispatch) => {
  dispatch({ type: POST_CONFIRM_AUDITOR_AFFORDANCE_LOADING });
  try {
    const res = await axios.post(`/assessment/assessmentquestionnairerequirement/${formData?.AssessmentQuestionnaireRequirementId}/action/confirm`, formData);
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE,
      });
    } else {
      dispatch({
        type: POST_CONFIRM_AUDITOR_AFFORDANCE_SUCCESS,
        payload: res?.data,
      });
      dispatch(searchAssessmentQuestionnaireRequirementList(formData?.AssessmentId));

    }
  } catch (err) {
    dispatch({
      type: POST_CONFIRM_AUDITOR_AFFORDANCE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_ASSESSMENT_QUESTIONNAIRE_REASON_LOADING = '@@assessments/POST_ASSESSMENT_QUESTIONNAIRE_REASON_LOADING';
export const POST_ASSESSMENT_QUESTIONNAIRE_REASON_SUCCESS = '@@assessments/POST_ASSESSMENT_QUESTIONNAIRE_REASON_SUCCESS';
export const POST_ASSESSMENT_QUESTIONNAIRE_REASON_FAILURE = '@@assessments/POST_ASSESSMENT_QUESTIONNAIRE_REASON_FAILURE';

export const postAssessmentQuestionnaireReason =
  (productVendorId, formData, assessmentQuestionnaireRequirementId = null) =>
  async (dispatch) => {
    dispatch({ type: POST_ASSESSMENT_QUESTIONNAIRE_REASON_LOADING });
    try {
      const response = await axios.post(
        `/serviceprovider/assessmentquestionnairerequirement/${formData?.AssessmentQuestionnaireRequirementId}/inadequacyreason`,
        formData
      );
      if (response?.data?.ErrorCount > 0) {
        const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
        dispatch(setErrors(errors));
        dispatch({
          type: POST_ASSESSMENT_QUESTIONNAIRE_REASON_FAILURE,
        });
      } else {
        dispatch({
          type: POST_ASSESSMENT_QUESTIONNAIRE_REASON_SUCCESS,
          payload: response?.data || {},
        });
        dispatch(searchAssessmentQuestionnaireRequirementList(formData?.AssessmentId, assessmentQuestionnaireRequirementId));
      }
    } catch (err) {
      dispatch({
        type: POST_ASSESSMENT_QUESTIONNAIRE_REASON_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_LOADING = '@@assessments/GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_LOADING';
export const GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_SUCCESS = '@@assessments/GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_SUCCESS';
export const GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_FAILURE = '@@assessments/GET_EVIDENCE_UPLOAD_DOCUMENT_URL_FAILURE';

export const getEvidenceReviewUploadDocumentUrl = (requirementId, evidenceId) => async (dispatch) => {
  dispatch({ type: GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_LOADING });

  try {
    const res = await axios.get(`/vendor/evidence/${evidenceId}/document/action/geturl`);
    if (res?.data?.ErrorCount > 0) {
      const errors = res?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_FAILURE,
        payload: {},
      });
    } else {
      dispatch({
        type: GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_SUCCESS,
        payload: res?.data,
        RequirementId: requirementId,
        EvidenceId: evidenceId,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_EVIDENCE_REVIEW_UPLOAD_DOCUMENT_URL_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const RESET_EVIDENCE_REVIEW_FLAGS = '@@assessments/RESET_EVIDENCE_REVIEW_FLAGS';

export const resetEvidenceReviewFlags =
  (data = {}) =>
  async (dispatch) => {
    dispatch({
      type: RESET_EVIDENCE_REVIEW_FLAGS,
      payload: data,
    });
  };

export const GET_ASSESSMENT_STATUS_HISTORY_LOADING = '@assessments/GET_ASSESSMENT_STATUS_HISTORY_LOADING';
export const GET_ASSESSMENT_STATUS_HISTORY_SUCCESS = '@assessments/GET_ASSESSMENT_STATUS_HISTORY_SUCCESS';
export const GET_ASSESSMENT_STATUS_HISTORY_FAILURE = '@assessments/GET_ASSESSMENT_STATUS_HISTORY_FAILURE';

export const getAssessmentStatusHistory =
  (assessmentId, source = '') =>
  async (dispatch) => {
    dispatch({ type: GET_ASSESSMENT_STATUS_HISTORY_LOADING });
    try {
      let response;
      if (source === PORTAL_2) {
        const body = getStatusHistoryForPortal(assessmentId);
        response = await axios.post(`/assessment/statushistory/search`, body);
      } else {
        const body = getStatusHistory(assessmentId);
        response = await axios.post(`/legacy/assessment/statushistory/search`, body);
      }
      if (response?.data?.ErrorCount > 0) {
        dispatch({
          type: GET_ASSESSMENT_STATUS_HISTORY_FAILURE,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_ASSESSMENT_STATUS_HISTORY_SUCCESS,
          payload: response?.data?.Data?.Rows || [],
        });
      }
    } catch (err) {
      dispatch({
        type: GET_ASSESSMENT_STATUS_HISTORY_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const REMEDIATION_GUIDANCE_SEARCH_LOADING = '@@assessments/REMEDIATION_GUIDANCE_SEARCH_LOADING';
export const REMEDIATION_GUIDANCE_SEARCH_SUCCESS = '@@assessments/REMEDIATION_GUIDANCE_SEARCH_SUCCESS';
export const REMEDIATION_GUIDANCE_SEARCH_FAILURE = '@@assessments/REMEDIATION_GUIDANCE_SEARCH_FAILURE';

export const getRemediationGuidanceList =
  (assessmentId, source = '', clientId = null) =>
  async (dispatch) => {
    dispatch({
      type: REMEDIATION_GUIDANCE_SEARCH_LOADING,
    });
    try {
      let res;
      if (source === PORTAL_2) {
        const body = getRemediationGuidanceSearchBodyForPortal(assessmentId, null, clientId);
        res = await axios.post(`assessment/productvendor/recommendations/search`, body);
      } else {
        const body = getRemediationGuidanceSearchBody(assessmentId, null);
        res = await axios.post(`/legacy/assessment/executivesummary/risksummary/search`, body);
      }
      if (res?.data?.ErrorCount > 0) {
        dispatch({
          type: REMEDIATION_GUIDANCE_SEARCH_FAILURE,
          payload: {},
        });
        if (res?.data?.Errors[0].Message !== NO_DATA_FOUND) {
          dispatch(setErrors(res?.data?.Errors));
        }
      } else {
        dispatch({
          type: REMEDIATION_GUIDANCE_SEARCH_SUCCESS,
          payload: res?.data?.Data?.Rows,
        });
      }
    } catch (err) {
      dispatch({
        type: REMEDIATION_GUIDANCE_SEARCH_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };
export const GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_LOADING = '@@assessments/GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_LOADING';
export const GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_SUCCESS = '@@assessments/GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_SUCCESS';
export const GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_FAILURE = '@@assessments/GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_FAILURE';

export const GetAssessmentEditDropdownOptionsList = () => async (dispatch) => {
  dispatch({ type: GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_LOADING });
  try {
    const body = getAssessmentEditOptionsBody();
    const response = await axios.post(`/serviceprovider/assessmentrequest/product/search`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_SUCCESS,
        payload: response?.data.Data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ASSESSMENT_EDIT_DROPDOWN_OPTIONS_LIST_FAILURE,
      payload: getErrorObject(err),
    });
  }
};
export const SET_ASSESSMENT_EDITED_OPTION_LOADING = '@@assessments/SET_ASSESSMENT_EDITED_OPTION_LOADING';
export const SET_ASSESSMENT_EDITED_OPTION_SUCCESS = '@@assessments/SET_ASSESSMENT_EDITED_OPTION_SUCCESS';
export const SET_ASSESSMENT_EDITED_OPTION_FAILURE = '@@assessments/SET_ASSESSMENT_EDITED_OPTION_FAILURE';

export const SetAssessmentEditDropdownOptionsList = (selectedAssessment, formData) => async (dispatch) => {
  dispatch({ type: SET_ASSESSMENT_EDITED_OPTION_LOADING });
  try {
    const body = {
      AssessmentId: selectedAssessment?.AssessmentId,
      EditAssessmentOptionId: selectedAssessment?.EditAssessmentOptionId,
      Comment: formData?.Notes,
    };
    const response = await axios.post(`/assessment/${selectedAssessment?.AssessmentId}/action/updateassessmentoption`, body);
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors;
      dispatch(setErrors(errors));
      dispatch({
        type: SET_ASSESSMENT_EDITED_OPTION_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: SET_ASSESSMENT_EDITED_OPTION_SUCCESS,
        payload: response?.data.Data || [],
      });
    }
  } catch (err) {
    dispatch({
      type: SET_ASSESSMENT_EDITED_OPTION_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const POST_ADDITIONAL_NOTE_LOADING = '@assessment/POST_ADDITIONAL_NOTE_LOADING';
export const POST_ADDITIONAL_NOTE_SUCCESS = '@assessment/POST_ADDITIONAL_NOTE_SUCCESS';
export const POST_ADDITIONAL_NOTE_FAILURE = '@assessment/POST_ADDITIONAL_NOTE_FAILURE';

export const addAdditionalNotes = (formData) => async (dispatch) => {
  dispatch({
    type: POST_ADDITIONAL_NOTE_LOADING,
  });

  try {
    const response = await axios.post(`/assessment/${formData?.AssessmentId}/additionalnote`, formData);
    if (response?.data?.ErrorCount > 0) {
      dispatch(setErrors(response?.data?.Errors));
      dispatch({
        type: POST_ADDITIONAL_NOTE_FAILURE,
      });
    } else {
      dispatch({
        type: POST_ADDITIONAL_NOTE_SUCCESS,
        payload: response?.data,
      });
      dispatch(searchAdditionalNotes(formData?.AssessmentId));
    }
  } catch {
    dispatch({
      type: POST_ADDITIONAL_NOTE_FAILURE,
    });
  }
};

export const ADDITIONAL_NOTES_SEARCH_LOADING = '@@assessments/ADDITIONAL_NOTES_SEARCH_LOADING';
export const ADDITIONAL_NOTES_SEARCH_SUCCESS = '@@assessments/ADDITIONAL_NOTES_SEARCH_SUCCESS';
export const ADDITIONAL_NOTES_SEARCH_FAILURE = '@@assessments/ADDITIONAL_NOTES_SEARCH_FAILURE';

export const searchAdditionalNotes =
  (assessmentId = null) =>
  async (dispatch) => {
    dispatch({
      type: ADDITIONAL_NOTES_SEARCH_LOADING,
    });
    try {
      const body = searchAdditionalNotesBody(assessmentId);
      const res = await axios.post(`/assessment/additionalnote/search`, body);
      if (res?.data?.ErrorCount > 0) {
        dispatch({
          type: ADDITIONAL_NOTES_SEARCH_FAILURE,
          payload: {},
        });
        if (res?.data?.Errors[0].Message !== NO_DATA_FOUND) {
          dispatch(setErrors(res?.data?.Errors));
        }
      } else {
        dispatch({
          type: ADDITIONAL_NOTES_SEARCH_SUCCESS,
          payload: res?.data?.Data?.Rows,
        });
        dispatch(resetAdditionalNotes());
      }
    } catch (err) {
      dispatch({
        type: ADDITIONAL_NOTES_SEARCH_FAILURE,
        payload: getErrorObject(err),
      });
    }
  };

export const RESET_ADDITIONAL_NOTES = '@assessments/RESET_ADDITIONAL_NOTES';

export const resetAdditionalNotes = () => async (dispatch) => {
  dispatch({
    type: RESET_ADDITIONAL_NOTES,
    payload: false,
  });
};

export const POST_CONFIRM_AUDITOR_MILESTONE_LOADING = '@@assessments/POST_CONFIRM_AUDITOR_MILESTONE_LOADING';
export const POST_CONFIRM_AUDITOR_MILESTONE_SUCCESS = '@@assessments/POST_CONFIRM_AUDITOR_MILESTONE_SUCCESS';
export const POST_CONFIRM_AUDITOR_MILESTONE_FAILURE = '@@assessments/POST_CONFIRM_AUDITOR_MILESTONE_FAILURE';

export const confirmAuditorMilestone = (formData, requirementId, assessmentQuestionnaireRequirementId) => async (dispatch) => {
  dispatch({ type: POST_CONFIRM_AUDITOR_MILESTONE_LOADING });
  try {
    const res = await axios.post(
      `/assessment/assessmentquestionnairerequirement/${formData?.AssessmentQuestionnaireRequirementId}/auditorreview/ipi/action/confirm`,
      formData
    );
    if (res?.data?.ErrorCount > 0) {
      dispatch(setErrors(res?.data?.Errors));
      dispatch({
        type: POST_CONFIRM_AUDITOR_MILESTONE_FAILURE,
      });
    } else {
      dispatch({
        type: POST_CONFIRM_AUDITOR_MILESTONE_SUCCESS,
        payload: res?.data,
        auditorStatus: formData?.AuditorStatusId,
      });
      dispatch(searchVendorProductRequirementDetails(requirementId, assessmentQuestionnaireRequirementId));
    }
  } catch (err) {
    dispatch({
      type: POST_CONFIRM_AUDITOR_MILESTONE_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const GET_QUESTIONNAIRE_LIST_DATA_LOADING = '@@assessments/GET_QUESTIONNAIRE_LIST_DATA_LOADING';
export const GET_QUESTIONNAIRE_LIST_DATA_SUCCESS = '@@assessments/GET_QUESTIONNAIRE_LIST_DATA_SUCCESS';
export const GET_QUESTIONNAIRE_LIST_DATA_FAILURE = '@@assessments/GET_QUESTIONNAIRE_LIST_DATA_FAILURE';

export const getQuestionnaireList = (callback) => async (dispatch) => {
  dispatch({ type: GET_QUESTIONNAIRE_LIST_DATA_LOADING });
  try {
    let body = getQuestionnaireListSearchBody();
    const response = await axios.post(`/questionnairetemplate/search`, JSON.stringify(body));
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: GET_QUESTIONNAIRE_LIST_DATA_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: GET_QUESTIONNAIRE_LIST_DATA_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: GET_QUESTIONNAIRE_LIST_DATA_FAILURE,
      payload: getErrorObject(err),
    });
  }
};

export const ADD_QUESTIONNAIRE_TO_ASSESSMENT_LOADING = '@@assessments/ADD_QUESTIONNAIRE_TO_ASSESSMENT_LOADING';
export const ADD_QUESTIONNAIRE_TO_ASSESSMENT_SUCCESS = '@@assessments/ADD_QUESTIONNAIRE_TO_ASSESSMENT_SUCCESS';
export const ADD_QUESTIONNAIRE_TO_ASSESSMENT_FAILURE = '@@assessments/ADD_QUESTIONNAIRE_TO_ASSESSMENT_FAILURE';

export const addQuestionnaireToAssessment = (assessmentId, searchData, callback) => async (dispatch) => {
  dispatch({ type: ADD_QUESTIONNAIRE_TO_ASSESSMENT_LOADING });
  try {
    let body = addQuestionnaireToAssessmentBody(assessmentId, searchData);
    const response = await axios.put(`/assessment/${assessmentId}`, JSON.stringify(body));
    if (response?.data?.ErrorCount > 0) {
      const errors = response?.data?.Errors.filter((it) => it.Message !== NO_DATA_FOUND);
      dispatch(setErrors(errors));
      dispatch({
        type: ADD_QUESTIONNAIRE_TO_ASSESSMENT_FAILURE,
        payload: [],
      });
    } else {
      dispatch({
        type: ADD_QUESTIONNAIRE_TO_ASSESSMENT_SUCCESS,
        payload: response?.data?.Data?.Rows,
      });
      if (callback) {
        callback();
      }
    }
  } catch (err) {
    dispatch({
      type: ADD_QUESTIONNAIRE_TO_ASSESSMENT_FAILURE,
      payload: getErrorObject(err),
    });
  }
};


export const SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_LOADING = '@@assessments/SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_LOADING';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_SUCCESS = '@@assessments/SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_SUCCESS';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE = '@@assessments/SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE';

export const getAssessmentQuestionnaireProgress =
  (assessmentId = null, assessmentQuestionnaireId = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_LOADING });
      const body = getAssessmentQuestionnaireProgressSearchBody(assessmentId, assessmentQuestionnaireId);
      try {
        const response = await axios.post(`assessment/${assessmentId}/questionnairetemplateprogress/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_PROGRESS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_QUESTIONNAIRE_PROGRESS_LOADING = '@@assessments/SEARCH_QUESTIONNAIRE_PROGRESS_LOADING';
export const SEARCH_QUESTIONNAIRE_PROGRESS_SUCCESS = '@@assessments/SEARCH_QUESTIONNAIRE_PROGRESS_SUCCESS';
export const SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE = '@@assessments/SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE';

export const getQuestionnaireProgress =
  (assessmentId = null, assessmentQuestionnaireId = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_QUESTIONNAIRE_PROGRESS_LOADING });
      const body = getAssessmentQuestionnaireProgressSearchBody(assessmentId, assessmentQuestionnaireId);
      try {
        const response = await axios.post(`assessment/${assessmentId}/questionnairetemplateprogress/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_QUESTIONNAIRE_PROGRESS_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_QUESTIONNAIRE_PROGRESS_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SET_SELECTED_QUESTIONNAIRE = '@@assessments/SET_SELECTED_QUESTIONNAIRE';

export const setSelectedQuestionnaire = (data = null) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_QUESTIONNAIRE,
    payload: data,
  });
};

export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING = '@@assessments/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS = '@@assessments/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE = '@@assessments/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE';

export const getAssessmentQuestionnaireRequirement =
  (assessmentQuestionnaireId = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_LOADING });
      const body = getAssessmentQuestionnaireRequirementSearchBody(assessmentQuestionnaireId);
      try {
        const response = await axios.post(`assessment/questionnaire/requirement/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };

export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING = '@@assessments/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS = '@@assessments/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS';
export const SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE = '@@assessments/SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE';

export const getAssessmentQuestionnaireRequirementGroup =
  (assessmentQuestionnaireId = null) =>
    async (dispatch) => {
      dispatch({ type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_LOADING });
      const body = getAssessmentQuestionnaireRequirementGroupSearchBody(assessmentQuestionnaireId);
      try {
        const response = await axios.post(`/assessment/questionnaire/requirement/group/search`, body);
        if (response?.data?.ErrorCount > 0) {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
            payload: [],
          });
        } else {
          dispatch({
            type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_SUCCESS,
            payload: response?.data?.Data?.Rows || [],
          });
        }
      } catch (err) {
        dispatch({
          type: SEARCH_ASSESSMENT_QUESTIONNAIRE_REQUIREMENT_GROUP_FAILURE,
          payload: getErrorObject(err),
        });
      }
    };